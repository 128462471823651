<template>
  <div
    v-if="postItems"
    class="mb-5"
  >
    <b-row>
      <b-col>
        <b-img-lazy
          :src="postItems.image"
          alt="jobOffer-Image"
          style="width: 100%; border-radius: 20px"
        />

        <b-card class="my-3 postCard">
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col class="mr-2">

              <!-- Title -->
              <h2
                v-if="postItems.title"
                class="my-3"
                style="font-size: 22px;"
              >
                {{ postItems.title }}
              </h2>

              <!-- All Text -->
              <div v-if="postItems.desc">
                <template v-for="item in postItems.desc">
                  <div :key="item">
                    <p>
                      {{ item }}
                    </p>
                  </div>
                </template>
              </div>

              <!-- Links -->
              <div v-if="postItems.link">
                <ul>
                  <template v-for="item in postItems.link">
                    <div :key="item.link">
                      <li>
                        <a
                          v-if="item.link"
                          class="custom-link"
                          @click="openLink(item.link)"
                        >
                          {{ item.title }}
                        </a>
                      </li>
                    </div>
                  </template>
                </ul>
              </div>

              <!-- Images -->
              <div v-if="postItems.postImages">
                <template v-for="item in postItems.postImages">
                  <div :key="item">
                    <b-img-lazy
                      :src="item"
                      alt="Post Image"
                      style="width: 100%; height:100%;"
                    />
                  </div>
                </template>
              </div>

            </b-col>
          </b-row>

          <b-card-footer
            v-if="postItems.categories || postItems.tags"
            style="background-color: white"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardFooter,
  BImgLazy,
} from 'bootstrap-vue'

import 'pixeden-stroke-7-icon'

export default {
  name: 'OnlyPost',
  components: {
    BRow,
    BCol,
    BCard,
    BCardFooter,
    BImgLazy,
  },
  props: {
    postItems: {
      type: Object,
      default: null,
      required: true,
    },
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank', 'noreferrer')
    },
  },
}
</script>

<style scoped>

  h2 {
    color: black !important;
  }

  p {
    color: grey !important;
    font-size: 17px;
  }

  span {
    color: #ff8f00 !important;
  }

  .postCard {
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    align-items: center;
  }

  a.custom-link {
    color: #ff8f00;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  @media (max-width: 700px){
  p {
    font-size: 15px;
  }
}

</style>
