<template>
  <div
    v-if="postFiltered[0]"
    class="posts"
  >

    <!-- Banner -->
    <banner
      :back-image="data.backgroundBanner"
      :title="postFiltered[0].title"
      height="600"
    />

    <div class="container-lg mt-5">
      <b-row>

        <!-- One Post -->
        <b-col
          lg="8"
          md="12"
        >
          <only-post :post-items="postFiltered[0]" />
        </b-col>

        <!-- Last posts -->
        <b-col
          lg="4"
          md="12"
        >

          <last-posts />

          <social-media />

        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Banner from 'Components/Common/Banner.vue'
import LastPosts from 'Components/Posts/LastPosts.vue'
import SocialMedia from 'Components/Posts/SocialMedia.vue'
import OnlyPost from 'Components/Posts/OnlyPost.vue'

import MyData from 'Data/posts.json'

import {
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'OnePost',
  components: {
    Banner,
    BRow,
    BCol,
    LastPosts,
    SocialMedia,
    OnlyPost,
  },
  data() {
    return {
      data: MyData.data,
      idPost: null,
      postFiltered: [],
    }
  },
  watch: {
    $route(to) {
      this.idPost = to.params.idPost
      if (this.idPost) this.postFilter(to.params.idPost)
    },
  },
  mounted() {
    this.data = MyData.data
    if ((typeof (this.$route.params.idPost) !== 'undefined') && (this.$route.params.idPost !== null)) this.idPost = this.$route.params.idPost.toString()
    // Comprueba si la id de la url coincide con alguna noticia o si existe o no una id en la url
    if ((this.idPost === null) || (!this.data.posts.some(e => e.id === this.idPost))) this.$router.push({ name: 'posts' })
    this.postFiltered = this.data.posts
    this.postFilter(this.idPost)
  },
  methods: {
    // Filtrar la noticia con la id correspondiente
    postFilter(idItem) {
      const arrayItemsFiltered = []
      this.data.posts.forEach(row => {
        if (row.id === idItem) {
          arrayItemsFiltered.push(row)
        }
      })
      this.postFiltered = arrayItemsFiltered
    },
  },
}
</script>

<style scoped>

  .posts {
    background-color: #f4f7f9;
  }

</style>
